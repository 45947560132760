(() => {
  'use strict';
  /*global $: false, _: false, angular: false */
  /* eslint no-loop-func:0, no-extend-native:0, max-statements:0 */
  /* jshint loopfunc: true */

  let am = angular.module('mtz-directives');

  // Temporary polyfill until Traceur adds this.
  if (!Array.prototype.find) {
    Array.prototype.find = function (fn) {
      for (let i = 0; i < this.length; i++) {
        let value = this[i];
        if (fn(value)) return value;
      }
      return undefined;
    };
  }

  // Temporary polyfill until Traceur adds this.
  if (!Array.prototype.includes) {
    Array.prototype.includes = function (value) {
      return this.indexOf(value) !== -1;
    };
  }

  function convertType(value) {
    let num = Number(value);
    return value === 'true' ? true :
      value === 'false' ? false :
      typeof value === 'boolean' ? value :
      !Number.isNaN(num) ? num :
      value;
  }

  function isNumeric(field) {
    let type = field.type;
    return type === 'money' || type === 'dollars' || type === 'number';
  }

  am.factory('manageSvc', [
    '$http', 'dateSvc', 'dialogSvc', 'httpUtil', 'rest',
    ($http, dateSvc, dialogSvc, httpUtil, rest) => {

      let fields;

      function getPropertyType(name) {
        let field = fields ?
          fields.find(field => field.property === name) :
          null;
        return field ? field.type : undefined;
      }

      function getFilterQueryParams(filters, prefix) {
        let s = '';

        if (filters) {
          Object.keys(filters).forEach(prop => {
            let value = filters[prop];
            if (value !== null && value !== undefined && value !== '') {
              let name = prop.endsWith('Min') || prop.endsWith('Max') ?
                prop.slice(0, -3) : prop;
              let type = getPropertyType(name);
              if (type === 'monthYear') {
                let [month, year] = value.split('/');
                let monthName = dateSvc.monthNumberToName(Number(month));
                value = monthName + ' ' + year;
              }

              s += '&' + prefix + '-' + prop + '=' + encodeURIComponent(value);
            }
          });
        }

        return s;
      }

      let prefix = rest.getRestUrlPrefix();

      function addQueryString(url, autoFilters) {
        let qs = getFilterQueryParams(autoFilters, 'af');
        return url + '?' + qs.substring(1); // change leading & to ?
      }

      return {
        addObject(resource, obj, autoFilters) {
          let url = addQueryString(prefix + resource, autoFilters);
          return $http.post(url, obj);
        },

        deleteObject(resource, id, autoFilters) {
          let url =
            addQueryString(prefix + resource + '/' + id, autoFilters);
          return $http.delete(url);
        },

        getFields(resource, autoFilters) {
          return $http.get(addQueryString(prefix + resource + '/field',
              autoFilters));
        },

        getObjects(resource, config) {
          let url = prefix + resource + this.getQueryString(config);
          return $http.get(url);
        },

        getQueryString({model, mode, sortField, reverse, // using destructuring
          autoFilters, filters, pageNum, headers, fields}) {

          let obj = {};
          if (sortField) {
            obj.sort = sortField.property;
            if (reverse) obj.reverse = reverse;
          }
          if (pageNum) obj.pageNum = pageNum;
          let qs = httpUtil.getQueryString(obj);

          qs += getFilterQueryParams(autoFilters, 'af');
          if (mode === 'search') {
            qs += '&filter-search=1';
            if (model && model.searchPageSize && model.searchPageSize !== -1) {
              qs += '&pageSize=' + model.searchPageSize;
            }
          } else {
            if (model && model.pageSize && model.pageSize !== -1) {
              qs += '&pageSize=' + model.pageSize;
            }
          }
          qs += getFilterQueryParams(headers, 'header');
          qs += getFilterQueryParams(filters, 'filter');

          if (fields) {
            // Add query parameters for checkbox filters.
            for (let field of fields) {
              if (!field.checkboxes) continue;

              let values = [];
              field.checkboxes.forEach((checked, index) => {
                if (checked) {
                  let value = field.uniqueValues[index];
                  values.push(value);
                }
              });

              if (values.length) {
                qs += '&filter-' + field.property + '=' +
                  values.map(value => encodeURIComponent(value)).join('|');
              }
            }
          }

          return qs;
        },

        getSearches(resource, autoFilters) {
          return $http.get(addQueryString(prefix + resource + '/search',
              autoFilters));
        },

        handleError(title, err) {
          dialogSvc.showError(title, err);
        },

        setFields(value) {
          fields = value;
        },

        toString(resource, id) {
          let config = {headers: {Accept: 'text/plain'}};
          let url = prefix + resource + '/' + id;
          return $http.get(url, config);
        },

        updateObject(resource, obj, autoFilters) {
          let url = addQueryString(
            prefix + resource + '/' + obj.id, autoFilters);
          return $http.put(url, obj);
        }
      };
    }
  ]);

  function setupScrolling() {
    window.addEventListener('scroll', () => {
      // If a visible date-picker dialog is found ...
      let ul = $('.manage-table-head .date-picker > ul:visible').first();
      if (ul.length) {
        let input = ul.prev();
        let inputTop = input.offset().top;
        let inputHeight = input.outerHeight();
        let scrollY = window.pageYOffset;
        let ulTop = inputTop + inputHeight - scrollY;
        ul.css('top', ulTop);
      }
    });
  }

  am.controller('ManageCtrl', [
    '$location', '$scope', '$timeout', 'csvSvc', 'dialogSvc',
    'domSvc', 'formatFilter', 'manageSvc', 'mtzConfirm', 'rest', '$rootScope', 'L10nFilter',
    ($location, $scope, $timeout, csvSvc, dialogSvc, domSvc,
    formatFilter, manageSvc, mtzConfirm, rest, $rootScope, l10nFilter) => {

      $scope.sessionStorage= sessionStorage;
      let dropDownDisplayed, tableBody, resource = $scope.resource;

      $rootScope.$on("filtersUpdated", function (event, args) {
        let databusPrefix = "databus/dealer-revenue-share/";
        $scope.autoFilters = args.any.filtersToApply;
        if(resource === databusPrefix+args.any.resourceUrl || resource === databusPrefix+args.any.resourceUrl + "-fr"){
          $scope.applyFilters();
        }
        if (resource === 'databus/onstar-guardian/guardian-transaction-corp-report') {
          $scope.applyFilters();
        }
      });
            

      $scope.stringToDate = function(date){
        return new Date(date);
      };

      $rootScope.$on("searchPax",function(event,args){
        $scope.filters = {"bac": "BAC:"+args.any.bac};
        $scope.applyFilters();
      });

      $scope.impersonate = function(gmin){
        $rootScope.$broadcast("impersonateEvent", {any: gmin});
      };

      function clearFilters() {
        let filters = $scope.filters;
        let haveRange = [
          'date', 'dateTime', 'money' , 'dollars', 'monthYear', 'number', 'year'
        ];

        let fields = $scope.fields;
        if (fields) {
          fields.forEach(field => {
            let prop = field.property;
            let type = field.type;
            if (haveRange.includes(type)) {
              // Deleting the property and setting to undefined do not work!
              filters[prop + 'Min'] = filters[prop + 'Max'] = null;
            } else {
              filters[prop] = null;
            }
          });
        }
      }

      function clearSearches() {
        $scope.searches.forEach(fields =>
          fields.forEach(field => field.value = null));
      }

      function createCsv(res) {
        let pageObj = res.data;

        let link = document.getElementById('manage-my-star-csv-download');
        let fields = $scope.fields.filter(field => !$scope.isHidden(field));

        let headings = fields.map(obj => obj.label);
        let properties = fields.map(obj => obj.property);

        // Configure transforms for special data types
        // so those values are formatted appropriately in the CSV.
        let transforms = {};
        // The ES6 Set class doesn't work yet with IE.
        //let specialTypes = new Set([
        //  'boolean', 'date', 'dateTime', 'dollars', 'monthYear', 'number'
        //]);
        let specialTypes = [
          'boolean', 'date', 'dateTime', 'money', 'dollars', 'monthYear', 'number'
        ];
        $scope.fields.forEach(field => {
          //if (specialTypes.has(field.type)) {
          if (specialTypes.indexOf(field.type) !== -1) {
            transforms[field.property] = value =>
              formatFilter(value, field.type);
          }
        });
        
        let fileName = $scope.resource;
        if(_.includes(fileName, '/')) {
          let fileNameSplit =  fileName.split('/')
          fileName = fileNameSplit[fileNameSplit.length-1]
        }

        // Modify the invisible link.
        csvSvc.downloadCsv(link,fileName,
          headings, properties, pageObj.items, transforms);
      }

      function getAutoFilters() {
        let filters = {};
        if ($scope.af) {
          $scope.af.split(',').forEach(s => {
            let [name, value] = s.split('=');
            name = name.trim();
            value = value.trim();
            filters[name] = convertType(value);
          });
        }
        return filters;
      }


      function getHeaderData() {
        let headerData = {};
        if ($scope.hd) {
          $scope.hd.split(',').forEach(s => {
            let [name, value] = s.split('=');
            name = name.trim();
            value = value.trim();
            headerData[name] = convertType(value);
          });
        }
        return headerData;
      }

      function getInputType(field) {
        let type = field.type;
        return type === 'boolean' ? 'checkbox' :
          type === 'number' ? 'number' :
          type === 'select' ? 'select' :
          'text';
      }

      function getObjectFromForm() {
        let obj = {};
        $scope.fields.forEach(field => {
          obj[field.property] = field.value;
        });
        return obj;
      }

      function handleError(res) {
        let err = res.status === 404 ?
          'No REST service was found at ' + res.config.url :
          res.data ? res.data : res.statusText;
        let title = $scope.header ?
          $scope.header + ' Error' :
          'REST Service Not Found';
        dialogSvc.showError(title, err);
      }

      function moveFocusToFirstVisibleInput() {
        setTimeout(() =>
          $('input:visible,select:visible').first().focus(), 100);
      }

      function processResponse(res) {
        let pageObj = $scope.pageObj = res.data;
        pageObj.totals = [];
        pageObj.goToPageNum = pageObj.pageNum;
        $scope.pageCount = Math.ceil(pageObj.itemCount / $scope.model.pageSize);

        if ($scope.canAdd &&
          $scope.mode === 'search' &&
          $scope.pageObj.itemCount === 0) {
          let msg = 'No matches were found.';
          if ($scope.canCreate) {
            msg += ' Would you like to create one?';
            mtzConfirm.show('Confirm', msg).then($scope.switchToCreate);
          } else {
            dialogSvc.showMessage('Not Found', msg);
          }
        }

        // Get unique field values on this page.
        // I wanted to use ES6 Set class for this, but it doesn't work in IE11.
        for (let field of $scope.fields) {
          if (field.type === 'string') {
            let prop = field.property;
            let set = []; //new Set();
            if (pageObj.items) {
              for (let item of pageObj.items) {
                let value = item[prop];
                if (value) set.push(value); //set.add(value);
              }
            }

            // Only save unique values if there are 10 or fewer of them.
            let size = set.length; //set.size;
            field.uniqueValues = size > 1 && size <= 10 ? _.uniq(set) : [];

            // Create an array of booleans to track the state of the checkboxes.
            let checkboxes = [];
            for (let i = 0; i < size; i++) {
              checkboxes[i] = false;
            }
            field.checkboxes = checkboxes;
          }
        }

        /*
        //TODO: Just to test new field type "link" ...
        let items = pageObj.items;
        if (items[0] && items[0].lineItem) {
          let url = 'http://ociweb.com/mark';
          for (let item of pageObj.items) {
            item.lineItemUrl = url;
          }
        }
        */
      }

      function setInputTypes(fields) {
        fields.forEach(field => field.inputType = getInputType(field));
      }

      function setPageNum(pageNum) {
        $scope.pageNum = pageNum;
        let pageObj = $scope.pageObj;
        if (pageObj) pageObj.goToPageNum = pageNum;
      }

      function setupGroups() {
        let group = {}, groups = [];

        $scope.fields.forEach(field => {
          let groupName = field.group;
          if (groupName === group.name) {
            group.size++;
          } else {
            group = {name: groupName, size: 1};
            groups.push(group);
          }
        });

        $scope.groups = groups;
      }

      function setupTable() {
        let promises = [
          domSvc.getElement('.manage-table-head'),
          domSvc.getElement('.manage-table-body')
        ];
        Promise.all(promises).then(jqs => {
          let [head, body] = jqs;
          tableBody = body;

          // Find the bottom y coordinate of the table head.
          let headBottomY = parseInt(head.css('margin-top')) + head.height();

          // Get references to DOM elements from the jQuery objects.
          let head0 = head[0], body0 = body[0];

          body.on('scroll', () => {
            if (dropDownDisplayed) {
              // Preventing scroll table body.
              body.css('overflow', 'hidden');
              return;
            }

            head0.scrollTop = body0.scrollTop - headBottomY;

            // Not sure why this delta is needed.
            let delta = body0.scrollLeft === 0 ? 0 : 1;
            head0.scrollLeft = body0.scrollLeft + delta;
          });
        });
      }

      function dynamicallyHeaders(headers) {
        const monthNames = [
          l10nFilter('month-names.jan', null, true),
          l10nFilter('month-names.feb', null, true),
          l10nFilter('month-names.mar', null, true),
          l10nFilter('month-names.apr', null, true),
          l10nFilter('month-names.may', null, true),
          l10nFilter('month-names.jun', null, true),
          l10nFilter('month-names.jul', null, true),
          l10nFilter('month-names.aug', null, true),
          l10nFilter('month-names.sep', null, true),
          l10nFilter('month-names.oct', null, true),
          l10nFilter('month-names.nov', null, true),
          l10nFilter('month-names.dec', null, true)
        ];
        const currentDate = new Date();
  
        currentDate.setMonth(currentDate.getFullYear() <= 2019 && currentDate.getMonth() < 4 ? 4: currentDate.getMonth());
        const dynamicDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth());
        const startMonthIndex = headers.map(e => e.property).indexOf('col01');
        
        headers.slice(startMonthIndex, headers.length).forEach(headerField => {
          const dateLabel = `${monthNames[dynamicDate.getMonth()]} ${dynamicDate.getFullYear()} %`;
          headerField.label = dateLabel;
          dynamicDate.setMonth(dynamicDate.getMonth() + 1);
        });
        return headers;
      }

      function updateTable() {
        // Don't load table data if fields haven't been loaded yet.
        if (!$scope.fields) return;

        if ($scope.mode === 'search') {
          // If no filter values have been entered, don't update the table.
          let filters = $scope.filters;
          let haveFilters = Object.keys(filters).some(
            key => filters[key] !== null);
          if (!haveFilters) return;
        }

        $scope.queryInProgress = true;
        let promise = manageSvc.getObjects(resource, $scope);
        if (promise) promise.then(
          res => {
            $scope.queryInProgress = false;
            processResponse(res);
          },
          res => {
            $scope.queryInProgress = false;
            handleError(res);
          });
      }

      //----------------------------------------------------------------------------

      let dropdownMenu, dropdownTop, initialScrollY;

      let onMac = /Mac/.test(navigator.platform);
      if (onMac) $('body').addClass('on-mac');

      setPageNum(1);

      if (typeof $scope.autoFilters === 'string') {
        $scope.autoFilters = getAutoFilters();
      }

      if (typeof $scope.headerData === 'string') {
        $scope.headerData = getHeaderData();
      }

      $scope.filters = {};
      $scope.mode = 'manage';
      $scope.pageSizes = [5, 20, 50, 100, 150, 200];

      //TODO: Should more properties be moved to this model object?
      let model = $scope.model = {};
      model.pageSize = $scope.pageSize;
      if (!model.pageSize) model.pageSize = $scope.pageSizes[0];
      model.searchPageSize = $scope.searchPageSize ?
        $scope.searchPageSize : $scope.pageSize;

      $scope.searchAmount = 'more';

      manageSvc.getFields(resource, $scope.autoFilters).then(
        res => {
          let fields = $scope.fields = resource.indexOf('onstar-rev-share-field-qualifier') !== -1 ? dynamicallyHeaders(res.data) : res.data;
          manageSvc.setFields($scope.fields);
          setInputTypes($scope.fields);

          $scope.sortField = fields.find(
            field => field.property === $scope.sortProperty);

          /*
          //TODO: For testing new autocomplete field type.
          for (let field of fields) {
            if (field.property === 'artist') testAutocomplete(field);
          }
          */

          setupGroups();
          //updateTable();
        },
        res => handleError(res));

      if ($scope.canSearch) {
        manageSvc.getSearches(resource, $scope.autoFilters).then(res => {
          let searches = res.data;
          searches.forEach(search => setInputTypes(search));

          /*
          //TODO: Just for testing regex support in search inputs.
          let firstSearch = searches[0];
          if (firstSearch) {
            let firstField = firstSearch[0];
            if (firstField.property === 'gmin') {
              firstField.regex = '^\\d{9}$';
              firstField.regexMsg = 'must be 9 digits';
            }
          }
          */

          /*
          //TODO: Just for testing combobox support.
          let firstSearch = searches[0];
          if (firstSearch) {
            let firstField = firstSearch[0];
            if (firstField.property === 'gmin') {
              firstField.type = 'combobox';
              firstField.options = ['red', 'green', 'blue'];
            }
          }
          */

          /*
          //TODO: For testing new autocomplete field type.
          for (let search of searches) {
            for (let field of search) {
              if (field.property === 'artist') testAutocomplete(field);
            }
          }
          */

          searches.forEach(search => setInputTypes(search));

          $scope.searches = searches;
        });
      }

      setupScrolling();
      setupTable();

      window.addEventListener('scroll', () => {
        if (dropdownMenu) {
          let top = dropdownTop + initialScrollY - window.pageYOffset;
          dropdownMenu.css('top', top);
        }
      });

      //----------------------------------------------------------------------------

      $scope.addObject = obj => {
        if (!obj) obj = getObjectFromForm();
        manageSvc.addObject(resource, obj, $scope.autoFilters).then(
          $scope.switchToSearch,
          handleError);
      };

      $scope.applyFilters = () => {
        setPageNum(1);
        $scope.update();
      };

      $scope.clearFilters = () => {
        clearFilters();
        updateTable();
      };

      $scope.clearForm = () => {
        $scope.editObj = null;
        let fields = $scope.fields;
        if (fields) fields.forEach(field => field.value = null);
      };

      $scope.deleteObject = (item, index, event) => {
        manageSvc.toString(resource, item.id).then(
          res => {
            let string = res.data;
            const msg = 'Are you sure you want to delete ' + string + '?';
            mtzConfirm.show('Confirm', msg).then(() =>
              manageSvc.deleteObject(
                resource, item.id, $scope.autoFilters).then(
                  () => {
                    $scope.clearForm();
                    $scope.pageObj.items.splice(index, 1);
                    $scope.pageObj.itemCount--;
                  },
                  handleError
                ));
          },
          handleError);

        // Don't allow a click on a "Delete" button
        // to be treated as a click on the table row
        // which starts an edit.
        event.preventDefault();
        event.stopPropagation();
        return false;
      };

      $scope.downloadCsv = () => {
         $scope.csvDownloading = true;
        let config = {
          mode: $scope.mode,
          sortField: $scope.sortField,
          reverse: $scope.reverse,
          autoFilters: $scope.autoFilters,
          filters: $scope.filters
        };
        resource =  resource + '?' +
          manageSvc.getQueryString(config) +
          '&authToken=' + sessionStorage.token;
        manageSvc.getObjects(resource, config).then(
          res => createCsv(res),
          res => handleError(res)).finally(()=>{$scope.csvDownloading = false;});
      };

      $scope.editObject = (index, obj) => {
        if (!$scope.canUpdate) return;
        $scope.editObj = obj;
        $scope.fields.forEach(field => field.value = obj[field.property]);
      };

      $scope.getFilterClass = (field, suffix) => {
        let form = $scope.reportForm;
        let input = form ? form[field.property + suffix] : null;
        return input && input.$invalid ?  'invalid-filter' : '';
      };

      $scope.getFirstPage = () => {
        setPageNum(1);
        updateTable();
      };

      $scope.getLastPage = () => {
        setPageNum($scope.pageCount);
        updateTable();
      };

      $scope.getNextPage = () => {
        $scope.pageNum++;
        updateTable();
      };

      $scope.getCsvUrl = () => {
        let config = {
          mode: $scope.mode,
          sortField: $scope.sortField,
          reverse: $scope.reverse,
          autoFilters: $scope.autoFilters,
          filters: $scope.filters,
          headers: $scope.headerData
        };

        return rest.getRestUrlPrefix() + resource + '?format=CSV' +
          manageSvc.getQueryString(config) +
          '&authToken=' + sessionStorage.token;
      };

      $scope.getPdfUrl = () => {
        let config = {
          mode: $scope.mode,
          sortField: $scope.sortField,
          reverse: $scope.reverse,
          autoFilters: $scope.autoFilters,
          filters: $scope.filters,
          headers: $scope.headerData
        };

        return rest.getRestUrlPrefix() + resource + '?format=PDF' +
        manageSvc.getQueryString(config) +
        '&authToken=' + sessionStorage.token;
      };

      $scope.getPreviousPage = () => {
        $scope.pageNum--;
        updateTable();
      };

      $scope.getItemClass = (item, field) => {
        let value = item[field.property];
        return $scope.getValueClass(value, field);
      };

      $scope.getValueClass = (value, field) => {
        return isNumeric(field) && value < 0 ? 'negative-number' : '';
      };

      $scope.getValues = field => field.options;

      $scope.goToPage = () => {
        let pageObj = $scope.pageObj;
        // If an invalid value was entered (zero or greater than page count),
        // go to the last page.
        if (pageObj.goToPageNum === undefined) {
          pageObj.goToPageNum = $scope.pageCount;
        }
        $scope.pageNum = pageObj.goToPageNum;
        updateTable();
      };

      $scope.hasNextPage = () => {
        let pageObj = $scope.pageObj;
        return pageObj ? pageObj.pageNum < $scope.pageCount : false;
      };

      $scope.hasPreviousPage = () => $scope.pageNum > 1;

      $scope.isHidden = field => $scope.hiddenProps.includes(field.property);

      $scope.isReadOnly = (field, obj) => {
        if (!obj) return false;
        if (obj._readOnly) return true; // all fields are read-only
        if (field.readOnly) return true;
        let rop = obj.readOnlyProps;
        return rop && rop.includes(field.property);
      };

      $scope.notImplemented = () => handleError('Not implemented yet');

      $scope.search = fields => {
        // Verify that required values were provided.
        let missingField = fields.find(field => field.required && !field.value);
        if (missingField) {
          let msg = 'A value must be entered for ' + missingField.label + '.';
          dialogSvc.showMessage('Required Value Missing', msg);
          return;
        }

        $scope.queryInProgress = true;

        // Copy search criteria into filters.
        clearFilters();
        let filters = $scope.filters;
        fields.forEach(field => {
          if (field.value !== '') {
            filters[field.property] = field.value;
          }
        });

        // Copy values from search fields to form fields
        // so they are prepopulated on the create screen.
        $scope.fields.forEach(field => {
          // Find the matching search field.
          let searchField = fields.find(
            searchField => searchField.property === field.property);
          field.value = searchField ? searchField.value : null;
        });

        setPageNum(1);
        manageSvc.getObjects(resource, $scope).then(
          res => {
            $scope.queryInProgress = false;
            processResponse(res);
          },
          res => {
            $scope.queryInProgress = false;
            handleError(res);
          });
      };

      $scope.showSearch = index =>
        $scope.searchAmount === 'more' ? index === 0 : true;

      $scope.sortOn = field => {
        $scope.reverse = field === $scope.sortField && !$scope.reverse;
        $scope.sortField = field;
        updateTable();
      };

      $scope.switchToCreate = () => {
        //$scope.clearForm();
        clearFilters();
        $scope.model.showFilters = false;
        $scope.mode = 'create';
        moveFocusToFirstVisibleInput();
      };

      $scope.switchToManage = () => {
        $scope.clearForm();
        clearFilters();
        $scope.model.showFilters = false;
        $scope.mode = 'manage';
        moveFocusToFirstVisibleInput();
        updateTable();
      };

      $scope.switchToSearch = keepFields => {
        if (!keepFields) clearSearches();
        clearFilters();
        $scope.model.showFilters = false;
        $scope.mode = 'search';
        moveFocusToFirstVisibleInput();
        $scope.pageObj = null;
      };

      $scope.tableHeadToggle = (open) => {
        dropDownDisplayed = open;
        if (!open) {
          // Resume scroll table body.
          tableBody.css('overflow', 'scroll');
          return;
        }

        // Find the dropdown that is visible.
        dropdownMenu = $('.dropdown-menu:visible');
        let caret = dropdownMenu.prev();
        let parent = dropdownMenu.parent();

        // Position the dropdown.
        // Setting the CSS position property to "fixed" prevents it
        // from being clipped by the bounds of the table header.
        let caretOffset = caret.offset();
        let caretWidth = parent.width();
        let caretHeight = parent.height();
        let right = caretOffset.left + caretWidth;
        initialScrollY = window.pageYOffset;
        dropdownTop = caretOffset.top + caretHeight - initialScrollY;
        let left = right - dropdownMenu.width();
        dropdownMenu.css({top: dropdownTop, left: left});
      };

      $scope.toggleSearches = () => {
        $scope.searchAmount = $scope.searchAmount === 'more' ? 'less' : 'more';
      };

      $scope.update = () => {
        updateTable();

        // Not sure why is this needed.
        $scope.reportForm.$setValidity();
      };

      $scope.updateObject = () => {
        let obj = getObjectFromForm();
        obj.id = $scope.editObj.id;
        manageSvc.updateObject(resource, obj, $scope.autoFilters).then(
          () => {
            $scope.clearForm();
            updateTable(); // so new object is in sorted order
          },
          handleError);
      };

      $scope.$watch('autoFilters', (newFilters, oldFilters) => {
        if (newFilters === oldFilters) return;

        setPageNum(1);
        updateTable();
      }, true); // deep watch

      $scope.$watch('model.pageSize', (newSize, oldSize) => {
        if (newSize === oldSize) return;

        setPageNum(1);
        updateTable();
      });

      /**
       * To trigger this,
       * $scope.$root.$broadcast(mode);
       * @param mode can be 'create', 'manage', or 'search'
       */
      $scope.$on('switch', (event, mode) => {
        let method = 'switchTo' + mode.capitalize();
        $scope[method]();
      });

      $scope.$on('update-' + $scope.resource, () => {
        $timeout(() => {
          setPageNum(1);
          $scope.update();
        });
      });
    }
  ]);

  am.directive('manageMyStar', () => {
    // JSHint complains if this form is used: ({ ... })
    return {
      restrict: 'AE',
      scope: {
        actionsOnLeft: '=',
        autoFilters: '=',
        canAdd: '=', // must use = instead of @ for booleans
        canCreate: '=', // must use = instead of @ for booleans
        canDelete: '=', // function passed an item and returns boolean
        canFilter: '=',
        canSearch: '=',
        canUpdate: '=',
        customClass: '=', // function passed an item & returns a CSS class name
        canImpersonate: '=', //create a button to impersonate the user based on the GMIN
        getDetail: '&', // a call to a function that returns detail html
        // displayed when a "show detail" button is pressed
        hasDetail: '&', // a call to a function that returns a boolean
        // indicating whether detail is available for a given item
        header: '@',
        headerData: '=',
        hideProps: '@', // comma-separated list of property names
        hidePdf: '=',
        manageActions: '=',
        manageHeading: '@',
        pageSize: '=', // must use = instead of @ for numbers
        searchPageSize: '=',  // -1 indicates no limit
        resource: '@',
        resourceSuffix: '@',
        searchActions: '=',
        searchHeading: '@',
        selectBtns: '=', // an array of objects with text and action properties
        // action is a function that is invoked when the button is pressed
        showTotalRow: '=',
        showCbFilters: '=', // show checkbox filters for <= 10 unique values
        singleSearchRow: '=', // for rare case when only
        // a single row of search data is expected
        sortProperty: '@'
      },
      controller: 'ManageCtrl',
      templateUrl: 'feature/share/directives/manage.html',
      link: (scope, element, attrs) => {
        // Not sure why scope.getDetail has a value
        // when the attribute isn't specified.
        if (!attrs.getDetail) delete scope.getDetail;
        if (!attrs.hasDetail) delete scope.hasDetail;

        // Setting a different scope property so if it isn't set,
        // we can set it to an empty array below.
        scope.manageActionsRef = scope.manageActions || [];

        if (scope.getDetail) {
          // Add a "show detail" action button.
          scope.manageActionsRef.push({
            glyph: 'expand',
            title: 'show detail',
            action(item) {
              // If the detail row is not currently displayed ...
              if (!item.showDetail) {
                scope.detail = scope.getDetail({item: item});
              }

              // Toggle showing detail row.
              item.showDetail = !item.showDetail;
            },
            isVisible(item) {
              return scope.hasDetail ?
                scope.hasDetail({item: item}) : true;
            }
          });
        }

        scope.resourceClass = 'manage-my-' + scope.resource;
        if (scope.resourceSuffix) {
          scope.resourceClass += '-' + scope.resourceSuffix;
        }
        scope.af = scope.autoFilters;
        scope.hd = scope.headerData;
        scope.hiddenProps = scope.hideProps ?
          scope.hideProps.split(',').map(s => s.trim()) :
          [];

        scope.actionCount =
          (scope.manageActionsRef ?
            Object.keys(scope.manageActionsRef).length : 0) +
          (scope.canDelete ? 1 : 0);

        // If selectBtns were supplied then
        // the user can select rows using checkboxes.
        scope.canSelect = !_.isEmpty(scope.selectBtns);

        scope.actOnSelectedRows = btn => {
          let ids = [];
          if (!scope.model.selectAll) {
            scope.pageObj.items.forEach(item => {
              if (item._selected) ids.push(item.id);
            });
          }

          let fn = btn.action;
          if (typeof fn === 'function') {
            fn(ids);
          } else {
            console.error(
              'non-function used for a select button action; action =', fn);
          }
        };

        scope.anyItemsSelected = () =>
          scope.model.selectAll ||
          scope.pageObj.items.some(item => item._selected);

        scope.getTotal = field => {
          // We can only get a total for certain field types.
          if (!isNumeric(field)) return '';

          let totals = scope.pageObj.totals;
          if (!totals) totals = scope.pageObj.totals = {};

          let property = field.property;

          // If we haven't yet computed the total for this property ...
          if (!totals[property]) {
            let items = scope.pageObj.items;
            totals[property] = items.reduce(
              (total, item) => total + item[property], 0);
          }

          return totals[property];
        };

        scope.searchActionCount =
          (scope.searchActions ? Object.keys(scope.searchActions).length : 0) +
          (scope.canAdd ? 1 : 0);

        scope.showActionColumn = () =>
          (scope.mode === 'manage' && scope.actionCount > 0) ||
          (scope.mode === 'search' && scope.searchActionCount > 0);

        scope.$watch('model.selectAll', checked => {
          let pageObj = scope.pageObj;
          if (!pageObj) return;
          let items = pageObj.items;
          if (!items) return;

          for (let item of items) {
            item._selected = checked;
          }
        });
      }
    };
  });

  am.directive('checkboxFilters', () => {
    return {
      restrict: 'AE',
      scope: {
        checkboxFilters: '=',
        field: '='
      },
      templateUrl: 'feature/share/directives/manage-checkbox-filter.html'
    };
  });
})();
